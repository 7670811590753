exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-fitnesscentre-tsx": () => import("./../../../src/pages/fitnesscentre.tsx" /* webpackChunkName: "component---src-pages-fitnesscentre-tsx" */),
  "component---src-pages-grandmastermurray-tsx": () => import("./../../../src/pages/grandmastermurray.tsx" /* webpackChunkName: "component---src-pages-grandmastermurray-tsx" */),
  "component---src-pages-hapkido-tsx": () => import("./../../../src/pages/hapkido.tsx" /* webpackChunkName: "component---src-pages-hapkido-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-information-tsx": () => import("./../../../src/pages/information.tsx" /* webpackChunkName: "component---src-pages-information-tsx" */),
  "component---src-pages-kickboxing-tsx": () => import("./../../../src/pages/kickboxing.tsx" /* webpackChunkName: "component---src-pages-kickboxing-tsx" */),
  "component---src-pages-taekwondo-tsx": () => import("./../../../src/pages/taekwondo.tsx" /* webpackChunkName: "component---src-pages-taekwondo-tsx" */)
}

